.main-footer {
	padding: 1% 0% 1% 0%;
	background: #C7C7C6;
	font-size: 18px;
	line-height: 30px;
	margin: 10px 0 0;

	a {

		color: #fff;
	}

	.footer-inner {
		.container {
			display: flex;
			flex-wrap: wrap;

			.footer-1,
			.footer-2,
			.footer-3 {
				width: 19%;
				padding: 0 10px 0 0;
				color: #fff;

				ul {
					list-style: none;
					padding: 0;
					margin: 0;

					li {
						display: flex;

						span {
							width: 70px;
							/*font-weight: 700;*/
						}
					}
				}
			}

			.footer-4 {
				padding: 30px 15px 20px 15px;
				width: 43%;

				a {
					text-transform: uppercase;
					color: var(--white);
					text-decoration: none;
					margin: 40px 0px 0px 10px;

					&:hover {
						color: var(--primarycolor);
						text-decoration: underline;
					}
				}
			}
		}

		.social_bl {
			a {
				display: inline-block;
				margin: 30px 0 0 10px !important;

				img {
					width: 30px;
					height: 30px;
					float: left;
					margin-right: 10px;
				}
			}
		}
	}



	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}



			color: @introcolor;
		}
	}


	footer {
		width: 100%;
		padding: 20px;
		text-align: center;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;

			font-size: 18px;

			background-color: @navbgcolor;

			color: #888;
		}


		.footer-link {
			color: #888;
		}


	}

}

@media all and (max-width: 767px) {
	.main-footer {
		padding: 30px 0;

		.footer-inner {
			.container {
				width: 85%;
				padding: 0;
				margin: 0 auto;

				.footer-0 {
					width: 100%;

					ul {
						padding: 0;
						list-style: none;
						display: flex;
						align-items: center;
						justify-content: space-around;
					}
				}

				.footer-4 {
					width: 100%;
					padding: 0;
					text-align: center;
				}
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 1199px) {
	.main-footer {
		.footer-inner {
			.container {

				.footer-1,
				.footer-2,
				.footer-3 {
					padding: 0 10px;
					width: 33.33%;
					text-align: center;

					ul {
						li {
							justify-content: center;
						}
					}
				}

				.footer-4 {
					width: 100%;
					text-align: center;
					padding: 25px 0 10px 0;
				}

			}
		}
	}
}