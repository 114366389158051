:root {
	--introImg: url(RESOURCE/img/head-index-XL.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 991px;
	--white: #fff;
	--primarycolor: #38526c;
	--sand: rgba(176, 162, 148, .7);
	--sanddark: rgba(176, 162, 148, .85);
	--textcolor: #666;
	/*--grey: #ccc;*/
	--grey: #f5090952;


	/*Calendar colors*/
	--not_available: #EC4747;
	/*--disabled: #CCC;*/
	//--disabled: #D7E6F9;
	--disabled: #d7e6f942;
	--available: #D7E6F9;
	--selected: #032c55;
	--selection_range: #6395FC;
	--selected_border: #fff;
	--text_color: #333;
}