@import "setup.css";

.header-main {

	.logo-area {
		height: 123px;
		overflow: hidden;
		display: block;

		@media (max-width:1440px) and (min-width:993px) {
			height: 93px;
			width: 330px;
		}

		@media (max-width:992px) {
			height: 81px;
		}
	}

	.header-right {
		.header-tel {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			a {
				color: var(--white);
				background: var(--primarycolor) url(RESOURCE/img/bg-tel.jpg) no-repeat left center;
				position: absolute;
				max-width: 200px;
				right: 15px;
				padding: 15px 15px 15px 35px;
				position: absolute;
				top: -40px;
				font-size: 18px;
				line-height: 30px;
				text-decoration: none !important;
			}
		}

		.header-menu {
			.navbar-area {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding: 70px 0 0 0;
				margin: 0;

				@media (max-width:1440px) and (min-width:993px) {
					padding-top: 40px;
				}

				>li {
					margin: 0 0 0 40px;
					padding: 0 0 40px 0;

					a {
						color: #9d9d9c;
						text-transform: uppercase;
						text-decoration: none;
						font-size: 22px;
						font-weight: 300;
						padding: 0 0 0 0;
						background: transparent;
						cursor: pointer;

						&:hover {
							color: var(--primarycolor);
						}
					}
				}

				.last-dropdown-menu {}

				.vo-dropdown-content {
					margin: 40px 0 0 0;
					border-top: 5px solid var(--primarycolor);
					list-style: none;
					padding: 0;

					>li {
						margin: 0;

						a {
							font-size: 16px;
							position: relative;
							text-transform: none;
							color: #4d5359;
							transition: all 0.2s;
							padding: 5px 5px 5px 10px;
							border-bottom: 1px solid var(--primarycolor);
							background: #f7f9fa;
							line-height: 35px;

							&:hover {
								background: var(--primarycolor);
								color: var(--white);
							}
						}
					}
				}

			}
		}
	}
}

.vo-nav-bar {
	background-color: var(--white);
	padding: 40px 0 0 0;


	.active {
		background-color: #4CAF50;
		color: white;
	}

	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 17px;
		border: none;
		outline: none;
		color: white;
		padding: 14px 16px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 200px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {

		color: white;
	}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}


@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar.opened {
		position: relative;
	}

	.vo-nav-bar.opened .icon {
		position: relative;
		right: 0;
		top: 0;
		background-color: transparent;
	}

	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-dropdown .vo-dropdown-content {
		display: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
	}

	.vo-dropdown .vo-dropdown-content {
		display: none !important;
	}

	.vo-dropdown.open .vo-dropdown-content {
		display: block !important;
	}

	.vo-dropdown.open .dropdown-arrow {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}

}

@media all and (max-width: 767px) {
	.header-main {
		.logo-area {
			height: 90px;
		}

		.header-right {
			.header-tel {
				a {
					width: 40px;
					background-position: 6px 4px;
					background-size: 50px;
					height: 40px;
					font-size: 0;
					padding: 0;
					top: -100px;
					right: 5px;
				}
			}
		}
	}
}

@media all and (max-width: 991px) {
	.vo-nav-bar {
		padding: 10px 0 0;

		.toggle-icon {
			background: url(RESOURCE/img/nav.png) #fff no-repeat center center;
			background-size: 40px;
			height: 50px;
			width: 100%;
			position: relative;
		}
	}

	.vo-nav-bar.opened {
		.header-right {
			.header-menu {
				.navbar-area {
					padding: 0;
					display: block;
					position: absolute;
					z-index: 1;
					background: #fff;
					left: 0;
					width: 100%;

					>li {
						width: 100%;
						padding: 15px 15px 15px 15px;
						border-bottom: 1px solid #ccc;
						margin: 0;

						a {
							text-align: center;
						}

						.dropdown-arrow {
							position: absolute;
							right: 0;
							top: 5px;
							display: flex !important;
							align-items: center;
							justify-content: center;
							height: 50px;
							width: 50px;
							background: url(RESOURCE/img/drop.svg) no-repeat center center;
							background-size: 30px;
						}
					}

				}
			}
		}
	}


	.header-main {
		.container {
			padding: 0;
		}

		.logo {
			text-align: center;

			img {
				max-width: 316px;
				margin: 5px auto;
				padding: 0 30px 0 0;
			}
		}

		.header-right {

			.header-menu {
				.navbar-area {
					padding: 0;
					display: none;

					li {
						width: 100%;
						position: relative;
					}

					.vo-dropdown-content {
						position: relative;
						margin: 15px -15px 0;
						border: none;
						box-shadow: none;

						li {
							a {
								background: #e6ded5;
								color: #38526D;
								border-bottom: 1px solid #ccc;
								display: block;
								padding: 15px 15px 15px 15px;
								font-size: 16px;
								text-transform: uppercase;
								line-height: 30px;

								&:hover {
									background: #eee;
									color: var(--primarycolor);
								}
							}
						}
					}
				}
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.header-main {
		.header-right {
			.header-tel {
				a {
					top: -91px;
					right: 10px;
				}
			}
		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.header-main {
		.header-right {
			.header-menu {
				.navbar-area {
					padding: 40px 0 0;

					>li {
						margin: 0 0 0 20px;

					}
				}
			}
		}
	}
}