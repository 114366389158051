@import "common.css";

.vo-unit-body {}


.unit-view {

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.unit-title {
		padding: 30px 0 0 0;

		h1 {
			font-size: 40px;
			font-weight: 300;
			margin: 0 0 15px 0;
			color: var(--primarycolor);
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.stars {
				margin: -10px 0 0 7px !important;
				font-size: 45px;

				@media (max-width: 588px) {
					margin: 0px 0 0 7px !important;
				}


			}
		}
	}

	.unit-icons {
		ul {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				padding: 0 10px;
				margin: 0 0 10px 0;

				a.mail {
					width: 50px;
					height: 50px;
					background: url(RESOURCE/img/icon-mail.svg);
					background-position: center;
					background-size: 55px;
					display: flex;
					border: 1px solid var(--white);
					margin: 0;
					background-repeat: no-repeat;
				}

				img {
					width: 50px;
					border: 1px solid var(--white);
				}

				.fav-element {
					display: flex;
					padding: 0;

					.fa-heart-o {
						font-size: 0;
						width: 50px;
						height: 50px;
						background: url(RESOURCE/img/icon-favorit.svg);
						background-size: cover;
						border: 1px solid var(--white);
						background-position: center;
					}

					.fa-heart {
						font-size: 0;
						width: 50px;
						height: 50px;
						background: url(RESOURCE/img/icon-favorit_no.svg);
						background-size: cover;
						border: 1px solid var(--white);
						background-position: center;
					}
				}
			}
		}
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}


	.calendar-area {
		@media (max-width:767px) {
			text-align: center;

			.datepicker-trigger {
				display: inline-block;
			}
		}
	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;
		color: var(--white);

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			/*justify-content: center;*/

			@media (max-width:767px) {
				justify-content: center;
			}
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			@media (max-width:767px) {
				justify-content: center;
			}

			&:first-child {
				@media (max-width:992px) {
					width: 100%;
				}
			}

			.day {
				width: 30px;
				height: 30px;
				//background-color: #7e7975;
				background-color: #f5f9fd;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
					margin: 0 6px 0 0;
				}

				&.day-free {
					background: var(--available);
				}

				&.day-full {
					background: var(--not_available);
				}

				&.day-end {
					background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important
				}

				&.day-start {
					background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
				}

				&.day-selected {
					background: var(--selection_range);
				}
			}
		}
	}


	.main-section {
		position: relative;

		h2 {
			margin-top: 40px;
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: static;
			margin: 20px 0 0 0;

			.unit-section-title {
				font-size: 24px;
				font-weight: 400;
				letter-spacing: 2px;
				line-height: 36px;
				text-align: center;
				margin: 0 0 30px 0;
				color: var(--primarycolor);
			}

			.white-title {
				color: var(--white) !important;
			}

			.blue {
				background: rgba(56, 81, 108, 0.8);
			}

			.sand {
				background: rgba(176, 162, 148, 0.7);
			}

			.unit-thumbnils {
				.v-img-gallery {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					grid-template-rows: repeat(4, 1fr);
					grid-auto-rows: 0;
					overflow-y: hidden;
					margin: 50px 0 0 0;

					a {
						.img-thumbnail {
							padding: 0;
							display: block;
							border-radius: 0;
							border: 2px solid var(--white);
							margin: 0 5px 5px 0;
							width: 162px;
							height: 108px;
							object-fit: cover;
						}

					}
				}
			}

			.unit-right-thumb {
				.title-img {

					position: relative;
					width: 100%;
					height: 482px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;


					.v-left-gallery-btn,
					.v-right-gallery-btn {
						font-size: 160px;
						position: absolute;
						top: 50%;
						margin-top: -57px;
					}

					.v-left-gallery-btn:hover,
					.v-right-gallery-btn:hover {
						color: #585858;
					}

					.v-right-gallery-btn {
						right: 40px;
					}

					.v-left-gallery-btn {
						left: 40px;
					}

				}

				.features {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					position: absolute;
					top: 5px;
					right: 20px;
				}
			}

			.unit-thumb-under {
				padding: 40px 0 40px 0;
				text-align: center;

				h3 {
					font-size: 28px;
					font-weight: 400;
					letter-spacing: 2px;
					line-height: 36px;
					margin-bottom: 15px;
					color: var(--textcolor);
					margin: 0;
				}
			}

			.unit-usstattung-section {

				color: var(--white);
				font-size: 18PX;
				padding: 50px 0 30px 0;

				.left {
					margin: 0 0 30px 0;

					ul {
						padding: 0 0 10px 20px;
						list-style-position: outside;
						list-style-type: circle;

						li {
							margin: 0 0 10px;
							font-size: 18px;
							line-height: 30px;
							width: 100%;


							span {
								position: relative;
								margin: 0 10px 0 0;

								&:after {
									display: block;
									content: ",";
									position: absolute;
									right: -5px;
									top: -2px;
								}

								&:last-child {
									&:after {
										display: none;
									}
								}
							}
						}
					}

					.Entfernungen {
						span {
							position: relative;
							margin: 0 10px 0 0;

							&:after {
								display: block;
								content: " · ";
								position: absolute;
								right: -9px;
								top: 0;
							}

							&:last-child {
								&:after {
									display: none;
								}
							}
						}

					}
				}

				.right {
					a {
						color: #fff;
						text-decoration: underline;
					}

					.table {
						max-height: 430px;
						overflow: hidden;
						height: 100%;
						display: block;

						tbody {
							width: 100%;

							.subinfo {
								width: 50%;
							}
						}

						.subrow,
						tr {
							&:hover {
								background: rgba(176, 162, 148, .7);
							}
						}
					}

					.table.fullview {
						max-height: 100%;
					}
				}

			}

			.Objektbeschreibung-section {
				padding: 30px 0;
				text-align: center;

				p {
					color: var(--textcolor);
					font-size: 18px;
					line-height: 30px;
					margin: 0;
				}
			}

			.unit-calnder-section {
				padding: 30px 0;

				h5 {
					font-size: 18px;
					line-height: 30px;
					margin: 0 0 30px 0;
					color: var(--white);
					text-align: center;
				}

				.Preise-Buchen {
					margin: 30px 0 0 0;

					.info-col-header {
						h2 {
							margin: 0;
							background: rgba(56, 81, 108, 0.7);
							padding: 5px;
							transition: 0.3s;
							color: var(--white);
							font-size: 18px;
							line-height: 30px;
						}
					}

					.info-col-main {
						.searchbox {
							margin: 0;

							.search-popup-trigger {
								background-color: var(--white);
								border-radius: 0;
								margin: 0;
							}

							#bs-datepicker-trigger {
								background: url(RESOURCE/img/icon-suche-datum.png) no-repeat 0 hsla(0, 0%, 100%, .85) !important;
								background-size: 30px 20px !important;
								padding: 0 0 0 40px;
								background-size: 25px !important;
								background-position: 5px 5px !important;
							}

							#guest-trigger {
								background: url(RESOURCE/img/icon-suche-personen.png) no-repeat 0 hsla(0, 0%, 100%, .85) !important;
								padding: 0 0 0 40px;
								background-size: 25px !important;
								background-position: 5px 6px !important;
							}
						}
					}

					.subprice {

						.table>tbody>tr>td {
							border-color: #ccc;
						}

						.price-table {
							margin: 0;
							border: none;

							tbody {
								border: none;
							}

							tr {
								td {
									padding-top: 7px;
									padding-bottom: 7px;
									border-bottom: none;
									background: #d7e6f9;
									color: var(--textcolor);
									font-weight: 400;
									font-size: 16px;
								}
							}

							tfoot {
								tr {
									td {
										background: var(--white);
										font-weight: 700;
									}
								}
							}
						}

						.subrow {
							margin: 30px 0 0 0;

							.book-btn {
								width: 100%;
								padding: 8px;
								cursor: pointer;
								background: rgba(56, 81, 108, 0.7);
								font-size: 22px;
								text-decoration: none;
								transition: 0.3s;
								border: 1px solid var(--white);
								font-weight: 500;
								border-radius: 0;
								color: var(--white) !important;
								transition: 0.3s;

								&:hover {
									background: rgba(176, 162, 148, 0.85);
								}
							}
						}

						.subrow.flow-buttons {
							display: flex;
							margin: 30px -15px 0 -15px;

							.book-btn {
								width: 50%;
								margin: 0 15px;
							}

							.btn-default {
								background: rgba(176, 162, 148, .85);

								&:hover {
									background: rgba(56, 81, 108, .7);
								}
							}

						}

						.optionals {
							h3 {
								margin: 0;
								background: rgba(56, 81, 108, 0.7);
								padding: 0 5px;
								transition: 0.3s;
								color: var(--white);
								font-size: 18px;
								line-height: 30px;
							}
						}
					}
				}
			}

			.subsection {

				padding: 30px 0;
			}

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 0;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: rgba(60, 63, 64, 0.9);
					color: var(--white);
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;
		margin: 0 0 30px 0;

		.unit-map {
			height: 500px;
			width: 100%;
			padding: 0;

			>div {
				height: 100%;
			}

			&.no-map {
				padding: 0 0 0 0;
			}

			.mapnote {
				font-size: 18px;
				margin: 0 0 15px;
				text-align: center;

				@media (max-width: 767px) {

					min-height: 130px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					min-height: 103px;
				}

				>a,
				.footer-link {
					color: var(--primarycolor);
				}
			}

			.mapconsent {
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				text-align: center;
				align-items: center;
				justify-content: center;
				padding: 15px;
				background: rgba(26, 121, 187, .15);


				p {
					margin: 0 0 20px 0;
				}


				a {
					background: #ff9c3c;
					background: var(--primarycolor);
					color: #fff;
					border-radius: 0px;
					padding: 0 15px;
					height: 36px;
					display: block;
					line-height: 35px;
					text-decoration: none !important;
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}
}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

/*.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}*/

@media all and (max-width: 940px) {
	.inner-banner {
		min-height: 320px;
	}

	.unit-view {
		.container-small {
			max-width: 450px;
			width: 100%;
		}

		.unit-title {
			h1 {
				font-size: 8vw;
			}
		}

		.unit-icons {
			ul {
				width: 55%;

				li {
					padding: 0 1px;
					margin: 0 0 10px;
					width: 16.5%;

					a.mail,
					img,
					.fav-element {
						width: 100%;
						height: 100%;
						background-size: contain;
					}

					.fav-element {
						.fa-heart-o {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}

		.unit-sec {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column-reverse;
		}

		.main-section {
			.main-col {
				padding: 0;

				.unit-section-title {
					font-size: 20px;
					margin: 0 0 5px 0 !important;
				}

				.unit-right-thumb {
					padding: 0;

					.title-img {
						height: 206px;
					}
				}

				.unit-thumbnils {
					padding: 0;

					.v-img-gallery {
						a {
							display: block;
							width: 100%;

							.img-thumbnail {
								width: 100%;
								height: 100%;
							}
						}
					}
				}

				.unit-thumb-under {
					h3 {
						font-size: 23px;
						line-height: 30px;
						margin-bottom: 5px;
					}
				}

				.unit-calnder-section {
					padding: 10px 0 10px 0;

					.legend-cal {
						ul {
							padding: 0 0 0 15px;
						}
					}
				}

				.unit-usstattung-section {
					padding: 20px 0 15px;

					.left {
						padding: 0;
					}

					.right {
						padding: 0;
					}
				}

				.subsection {
					padding: 10px 15px 10px 15px;

					.container-small {
						padding: 0;
					}
				}

				.Objektbeschreibung-section {
					padding: 15px 0;
				}

			}
		}

	}
}

@media all and (max-width: 767px) {
	.unit-view {

		.main-section {
			.main-col {
				.unit-calnder-section {
					.Preise-Buchen {
						.info-col-main {
							.layout-row {
								flex-wrap: wrap;
							}

							.searchbox {
								margin: 0;
								width: 100%;
								flex: unset;
							}
						}

						.subprice {
							.subrow.flow-buttons {
								flex-wrap: wrap;

								.book-btn {
									width: 100%;
									margin: 0 15px 10px 15px;

								}
							}
						}
					}
				}
			}
		}
	}
}

@media all and (min-width: 481px) and (max-width: 767px) {
	.unit-view {
		.main-section {
			.main-col {
				.unit-right-thumb {
					.title-img {
						height: 260px;
					}
				}

				.unit-thumbnils {
					.v-img-gallery {
						display: flex;
						width: 100%;
						flex-wrap: wrap;


						a {
							width: 50%;
						}
					}
				}
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.unit-view {
		.main-section {
			.main-col {
				.unit-right-thumb {
					.title-img {
						height: 400px;
					}
				}

				.unit-thumbnils {


					.v-img-gallery {
						a {
							width: 100%;
						}
					}
				}

				.unit-usstattung-section {
					.left {
						margin: 0 0 30px 0;
					}
				}
			}
		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.unit-view {
		.main-section {
			.main-col {
				.unit-thumbnils {
					.v-img-gallery {

						a {
							.img-thumbnail {
								width: 145px;
							}
						}
					}
				}
			}
		}
	}
}

@media print {}