@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-BlackItalic.eot');
	src: url('RESOURCE/fonts/SourceSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-BlackItalic.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-BlackItalic.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-BlackItalic.svg#SourceSansPro-BlackItalic') format('svg');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-BoldItalic.eot');
	src: url('RESOURCE/fonts/SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-BoldItalic.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-BoldItalic.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-BoldItalic.svg#SourceSansPro-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLightItalic.eot');
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLightItalic.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLightItalic.svg#SourceSansPro-ExtraLightItalic') format('svg');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot');
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Italic.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Italic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Italic.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Italic.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Italic.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Italic.svg#SourceSansPro-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-LightItalic.eot');
	src: url('RESOURCE/fonts/SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-LightItalic.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-LightItalic.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-LightItalic.svg#SourceSansPro-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/RESOURCE/fonts/SourceSansPro-SemiBold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-SemiBoldItalic.eot');
	src: url('RESOURCE/fonts/SourceSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-SemiBoldItalic.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBoldItalic.svg#SourceSansPro-SemiBoldItalic') format('svg');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}


@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}

body {
	font-family: 'Source Sans Pro', sans-serif;
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.p-0 {
	padding: 0 !important;
}

.pt-30 {
	padding-top: 30px !important;
}

.pb-30 {
	padding-bottom: 30px !important;
}

.mb-0 {
	margin-bottom: 0 !important;

}

.mb-70 {
	margin-bottom: 70px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: var(--white) !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}

.text-left {
	text-align: left !important;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: var(--white);
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {


	.asd__day--not-available {
		background: var(--not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--disabled) 50%, var(--not_available) 50%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--disabled) 50%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--selection_range) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: var(--disabled) !important;
	}

	.asd__day--enabled {
		background-color: var(--available) !important;
		color: var(--text_color) !important;

		/*button {
			text-decoration: underline;
		}*/

		&:hover {
			background-color: var(--selected) !important;
			color: white !important;
		}



		&.asd__day--disabled {
			background-color: var(--disabled) !important;
			color: var(--grey) !important;
			text-decoration: underline;
			opacity: 1 !important;

			&.asd__day--selected {
				background-color: var(--selected) !important;
				color: var(--white) !important;
			}

			&.asd__day--in-range {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
				color: var(--white) !important;
			}

			&.asd__day--hovered {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}
		}

		&.asd__day--arrival {
			&:hover {
				background-color: var(--selected) !important;
			}
		}

		&.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
			color: var(--text_color) !important;
		}
	}

	.asd__day--selected {
		background: var(--selected) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;

		/*Arrow display css start*/
		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				left: 0px;
			}
		}

		/*Arrow display css end*/
	}


}

a,
input,
button,
.btn,
*,
a:focus,
input:focus,
button:focus,
.btn:focus,
.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
	outline-offset: 0 !important;
	text-decoration: none;
}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.container {
	max-width: 1420px;
	width: 100%;
	padding: 0 10px;
}

.wrapper {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}

.asd__mobile-close {
	padding: 2px 8px !important;
	top: 10px !important;
	border: 1px solid #000 !important;
	right: 15px !important;
}

@font-face {
	font-family: 'baeckerfont';
	src: url("RESOURCE/fonts/baeckerfont.eot");
	src: url("RESOURCE/fonts/baeckerfont.eot") format("embedded-opentype"),
		url("RESOURCE/fonts/baeckerfont.ttf") format("truetype"),
		url("RESOURCE/fonts/baeckerfont.woff") format("woff"),
		url("RESOURCE/fonts/baeckerfont.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'baeckerfont';
	src: url("../fonts/baeckerfont.eot?9llon5");
	src: url("../fonts/baeckerfont.eot?9llon5#iefix") format("embedded-opentype"), url("../fonts/baeckerfont.ttf?9llon5") format("truetype"), url("../fonts/baeckerfont.woff?9llon5") format("woff"), url("../fonts/baeckerfont.svg?9llon5#baeckerfont") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="ic-"],
[class*=" ic-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'baeckerfont' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fe-icon {
	&:before {
		display: block;
	}
}

/* Waschmaschine */
.ic-204:before {
	content: "\e90e";
}

/* W-Lan */
.ic-114:before {
	content: "\e90f";
}

/* Balkon */
.ic-6:before {
	content: "\e900";
}

/* Baulaerm */
.ic-5166:before {
	content: "\e901";
}

/* Kamin */
.ic-56:before {
	content: "\e905";
}

/* Meerblick */
.ic-63:before {
	content: "\e909";
}

/* Sauna */
.ic-24:before {
	content: "\e90a";
}

/* Geschirrspueler */
.ic-5:before {
	content: "\e90b";
}

/* Strandkorb */
.ic-22:before {
	content: "\e90c";
}

/* Terrasse */
.ic-7:before {
	content: "\e90d";
}

.ic-pets_anfrage:before {
	content: "\e902";
}

.ic-pets_notallow:before {
	content: "\e903";
}

.ic-pets_allow:before {
	content: "\e904";
}

.ic-logo:before {
	content: "\e906";
}

.fe-icon,
.fe-sm-icon,
.fe-md-icon,
.fe-lg-icon {
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
	text-align: center;
	display: inline-block;
	opacity: 0.9;
	filter: alpha(opacity=50);
	/* For IE8 and earlier */
	display: flex;
	align-items: center;
	justify-content: center;
}

.fe-sm-icon {
	font-size: 22px;
	background: var(--white);
	border: 1px solid var(--primarycolor);
	color: var(--primarycolor);
	width: 32px;
	height: 32px;
	line-height: 30px;
}

.fe-md-icon {
	font-size: 30px;
	background: var(--white);
	border: 1px solid var(--primarycolor);
	color: var(--primarycolor);
	width: 42px;
	height: 42px;
	line-height: 38px;
}

.fe-lg-icon {
	font-size: 30px;
	background: var(--white);
	border: 1px solid var(--primarycolor);
	color: var(--primarycolor);
	width: 42px;
	height: 42px;
}

.inner-banner {
	min-height: 445px;
	background: url(RESOURCE/img/head-index-XL.jpg) no-repeat center;
	background-size: cover;
	background-size: cover;
	position: relative;

	.banner-inner {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.left {
		.left-inner {
			background: url(RESOURCE/img/slogan-bg.png);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: right center;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			padding: 0 60px;
			align-items: center;
			height: 445px;

			h2 {
				font-size: 70px;
				font-weight: 700;
				line-height: normal;
				color: var(--white);
				margin: 0;

				text-align: left;

				span {
					font-size: 70px;
					font-weight: 300;
				}
			}
		}
	}

	.right {

		min-height: 445px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.checkbox label:before {
			border-radius: 0 !important;
		}

		.searchbar {

			margin-top: 0;
			background: transparent;
			padding: 0 0 50px 110px;

			.row {
				margin: 0;
				display: flex;
				flex-wrap: wrap;
			}

			.first-input {
				padding: 5px;

				.form-control {
					padding: 10px;
					padding-left: 40px;
					height: 53px;
					font-size: 1.2em;
					position: relative;
					width: 100%;
					border: none;
					background: url(RESOURCE/img/icon-suche-ort.png) no-repeat left center rgba(255, 255, 255, 0.85) !important;
					border-radius: 0;
				}
			}



			.datepicker-trigger {
				.search-popup-trigger {
					background: url(RESOURCE/img/icon-suche-datum.png) no-repeat left center rgba(255, 255, 255, 0.85) !important;
					background-size: 30px 20px !important;
				}
			}

			.guest-trigger {
				.search-popup-trigger {
					background: url(RESOURCE/img/icon-suche-personen.png) no-repeat left center rgba(255, 255, 255, 0.85) !important;
				}
			}

			.searchdata-rooms {
				.search-popup-trigger {
					background: url(RESOURCE/img/door-icon.png) no-repeat left center rgba(255, 255, 255, 0.85) !important;
					background: url(https://r.v-office.com/preview/v381/1615565575000/img/door-icon.png) no-repeat 0 hsla(0, 0%, 100%, .85) !important;
					background-size: 22px !important;
					background-position: 9px 14px !important;
				}
			}

			.searchdata-type {
				.search-popup-trigger {
					background: url(RESOURCE/img/house-icon.png) no-repeat left center rgba(255, 255, 255, 0.35) !important;
					background: url(https://r.v-office.com/preview/v381/1615565575000/img/house-icon.png) no-repeat 0 hsla(0, 0%, 100%, .35) !important;
					background-size: 24px !important;
					background-position: 8px 16px !important;
				}
			}

			.searchdata-rooms,
			.searchdata-type {
				position: relative;

				&:after {
					background: url(RESOURCE/img/down-arrow.png) no-repeat left center !important;
					display: block;
					content: " ";
					width: 15px;
					height: 15px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 15px !important;
					position: absolute;
					right: 20px;

					top: 26px;
					opacity: 0.8;
				}
			}

			.searchbox {
				padding: 5px;
				width: 50%;

				#region-trigger,
				.search-popup-trigger {
					padding: 10px;
					padding-left: 40px;
					height: 53px;
					font-size: 18px;
					position: relative;
					width: 100%;
					background: rgba(255, 255, 255, 0.85);
					border: none;
					color: #3E3D40;
				}

				.region-trigger {
					background: rgba(255, 255, 255, 0.85) url(RESOURCE/img/map-pin-icon.png) no-repeat scroll 11px !important;
				}


				input,
				.search-btn,
				select {
					border-radius: 0;

				}

				.search-btn {
					width: 100%;
					font-weight: 400;

				}

				.search-popup-trigger {
					cursor: pointer;
					-moz-appearance: none;
					-webkit-appearance: none;
					appearance: none;
					padding: 0 40px;

				}

				.form-control:disabled,
				.form-control[readonly] {}
			}

			.button-groups {
				width: 100%;
				display: flex;

				.suchen-trigger {
					.search-btn {
						background: var(--sanddark);
						height: 53px;
						border: var(--white);
						line-height: 53px;
						padding: 0 10px;
						box-shadow: none;
						font-size: 22px;
						font-weight: 300;
						transition: 0.3s;
						height: 58px;
						border: 1px solid var(--white);

						&:hover {
							background: rgba(56, 81, 108, 0.7);
							color: var(--white);
						}
					}
				}

				.last-input {
					padding: 5px;
					margin: 0 0 0;
					width: 50%;

					.zur-erweiterten {
						background: rgba(56, 81, 108, 0.95);
						font-size: 22px;
						font-weight: 300;
						text-align: center;
						color: var(--white);
						border: 1px solid #96a7b9;
						text-decoration: none;
						transition: 0.3s;
						width: 100%;
						height: 58px;
						outline: none;

						&:hover {
							background: rgba(56, 81, 108, 0.85);
							color: var(--white);
							border: 1px solid #96a7b9;
							transition: 0.3s;
						}
					}
				}
			}

			.advance-filed {
				display: flex;
				width: 98%;
				flex-wrap: wrap;
				align-items: center;
				background: hsla(0, 0%, 100%, .85) !important;
				margin: 10px auto;

				.list {
					width: 25%;
					margin: 0;
					padding: 0 15px;

					.form-group {
						margin: 0;

						label:before {
							top: 2px;
						}

						lable:after {
							top: 1px;
						}

						.checkbox-success input[type=checkbox]:checked+label:before {
							background-color: rgba(176, 162, 148, .85);
							border-color: rgba(176, 162, 148, .85);
							outline: none;
						}
					}
				}
			}

			@media (min-width:768px) {
				max-width: 650px;
			}

			@media (min-width:993px) {
				max-width: 950px;
			}

		}
	}
}

.blue {
	background: rgba(56, 81, 108, 0.8);
}

.sand {
	background: rgba(176, 162, 148, .7);
}

.tooltip-inner {
	background-color: var(--primarycolor);
}

.tooltip.top .tooltip-arrow {
	border-top-color: var(--primarycolor);
}

.show-mob {
	display: none;
}

.dlg {
	h4 {
		font-weight: 700;

	}
}

.payment-view {
	padding: 30px 0;

	h1 {
		font-size: 26px;
		font-weight: 400;
		text-transform: uppercase;
		color: var(--primarycolor);
		letter-spacing: 2px;
		line-height: 40px;
		margin: 0 0 15px;
	}

	.btn-primary.active {
		background-color: rgba(56, 81, 108, .95) !important;
		border: 1px solid rgba(56, 81, 108, .95) !important;
		border-radius: 0;
		outline: none !important;
		-webkit-transition: .3s;
		transition: .3s;
		box-shadow: none;
		font-weight: 300;

		&:hover {
			background-color: var(--sand) !important;
			border: 1px solid var(--sand) !important;
		}
	}
}

.btn-default {
	background: var(--sand);
	color: #fff;
	border-radius: 0;

	&:hover {
		border: 1px solid var(--primarycolor);
		background: var(--primarycolor);
	}
}

.btn {
	border-radius: 0 !important;
}

.btn-primary {
	background: var(--primarycolor);
	border: 1px solid var(--primarycolor);
	border-radius: 0;
	outline: none !important;
	-webkit-transition: .3s;
	transition: .3s;

	&:hover {
		background: var(--sand);
		border: 1px solid var(--sand);
	}
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.discount-box {
	background: red;
	color: #fff;
	padding: 7px 25px;
	font-size: 18px;
	margin: 5px 15px;
	line-height: normal;
	display: inline-block;
	letter-spacing: normal;
}

.sale-box {
	background: red;
	color: #fff;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 200px;
	width: 100%;
	height: 35px;
	transform: rotate(320deg);
	-ms-transform: rotate(320deg);
	-webkit-transform: rotate(320deg);
	position: absolute;
	left: -65px;
	top: 15px;

	@media all and (min-width: 768px) and (max-width: 991px) {
		left: -30%;
		top: 10%;
	}
}

@media all and (max-width: 767px) {
	.show-mob {
		display: block !important;
	}

	.desktop-show {
		display: none !important;
	}

	.inner-banner {
		padding: 30px 0;
		min-height: auto;

		.banner-inner {
			.left {
				display: none;
			}

			.right {
				padding: 0;

				.searchbar {
					padding: 0;

					.first-input {
						padding: 2px;
					}

					.searchbox {
						padding: 2px;
						width: 100% !important;
					}

					.button-groups {
						flex-wrap: wrap;

						.last-input {
							width: 100%;
							padding: 2px;

							.zur-erweiterten {
								padding: 2px;
								width: 100%;
								height: 43px;
							}
						}

						.suchen-trigger {
							width: 100%;
							padding: 2px;

							.search-btn {
								padding: 2px;
								width: 100%;
								height: 43px;
								line-height: 35px;
							}

						}
					}
				}
			}
		}
	}
}

@media all and (min-width: 320px) and (max-width: 575px) {
	.inner-banner {
		.right {
			.searchbar {
				.advance-filed {
					.searchdata-type {
						padding: 5px 10px;

						select {
							border: 1px solid rgb(0 0 0 / 10%) !important;
						}
					}

					.list {
						width: 100%;
						padding: 0 10px;
					}
				}
			}
		}
	}
}

@media all and (min-width: 576px) and (max-width: 767px) {
	.inner-banner {
		.right {
			.searchbar {
				.advance-filed {

					.list {
						width: 33.33%;
					}
				}
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.inner-banner {
		min-height: 530px;

		.left {
			padding: 0;

			.left-inner {
				min-height: 530px;
				padding: 0 0 0 30px;

				h2 {
					font-size: 40px;

					span {
						font-size: 40px;
					}

				}
			}
		}

		.right {
			padding: 0;

			.searchbar {
				padding: 0 0 50px 15px;

				.searchbox {
					width: 100%;
				}

				.button-groups {
					.last-input {
						.zur-erweiterten {
							font-size: 16px;
						}
					}

					.searchbox {
						width: 50%;
					}

					.suchen-trigger {
						.search-btn {
							font-size: 16px;
						}
					}
				}

				.advance-filed {
					padding: 10px 0;
					margin: 0 auto;

					.list {
						width: 50%;
						margin: 0;
						padding: 0 15px;

						.checkbox {
							margin: 5px 0;
						}
					}
				}
			}
		}

	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.inner-banner {
		min-height: 530px;

		.left {
			.left-inner {
				min-height: 530px;

				h2 {
					font-size: 50px;

					span {
						font-size: 50px;
						font-weight: 300;
					}
				}
			}
		}

		.right {
			.searchbar {
				padding: 0;
				max-width: 100%;
				padding: 0 0 50px 0;

				.searchbox {
					width: 100%;
				}

				.advance-filed {
					.list {
						width: 33.33%;
					}
				}

				.button-groups {
					.last-input {
						.zur-erweiterten {
							font-size: 18px;
						}
					}

					.searchbox {
						width: 50%;
					}

					.suchen-trigger {
						.search-btn {
							font-size: 18px;
						}
					}
				}
			}

		}
	}
}

@media all and (min-width: 1200px) and (max-width: 1300px) {
	.inner-banner {
		.right {
			.searchbar {
				padding: 0 0 50px 20px;
			}
		}
	}
}


@media print {
	.print-img {
		opacity: 1;
		visibility: visible;
	}

	a[href]:after {
		content: " ("attr(href) ")";
	}

	a[href]:after {
		content: none !important;
	}

	.v-img-gallery a {
		width: 33.33% !important;
		display: block !important;
	}

	.v-img-gallery a img {
		width: 100% !important;
		height: auto !important;
	}

	h4.visible-print {
		font-weight: bold;
		margin-top: 30px;
	}

	.pagebreak {
		page-break-after: always;
	}

	.unit-view .main-section .main-col .unit-usstattung-section .right .table {
		height: auto !important;
		max-height: inherit !important;
	}

}

.covid-popup {
	position: absolute;
	bottom: 10px;
	padding: 0 0 0 110px;
	text-align: center;
	width: 100%;

	.covid-btn {
		color: #fff;
		padding: 0;
		background: transparent;
		font-size: 25px;
		border-bottom: 1px solid #fff;
		box-shadow: none;
		font-weight: 600;
	}

	h4 {
		font-size: 26px;
		font-weight: 400;
		text-transform: uppercase;
		color: var(--primarycolor);
		letter-spacing: 2px;
		line-height: 40px;
		margin: 0 0 15px;
	}

	p {
		color: var(--textcolor);
		font-size: 18px;
		line-height: 30px;
		margin: 0 0 15px;
	}

	a {
		color: var(--primarycolor);
		word-break: break-all;
	}

	.modal-dialog {
		max-width: 900px;
		width: 100%;
		background: #fff;
		margin: 50px auto;

		.close {
			position: absolute;
			z-index: 1;
			background: var(--sand);
			width: 30px;
			height: 30px;
			color: #000;
			opacity: 0.5;
			text-shadow: none;
			right: 15px;
			top: 15px;
		}

		.modal-content {
			padding: 50px 0 0 0 0;
			text-align: center;
			border-radius: 0;
			border: 7px solid #e0dad4;

			.modal-body {
				padding: 0 30px 30px 30px;
			}

		}
	}
}


@media all and (max-width: 767px) {
	.covid-popup {
		position: static;
		padding: 15px 0 0 0;

		.modal-dialog {
			margin: 0 auto;
		}


		h4 {
			font-size: 22px;
			line-height: 34px;
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.covid-popup {
		bottom: 10px;
		padding: 0;



		h4 {
			font-size: 18px;
			line-height: 30px;
		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.covid-popup {
		bottom: 10px;
		padding: 0;
	}
}

@media all and (min-width: 1200px) and (max-width: 1300px) {
	.covid-popup {

		padding: 0 0 0 20px;
	}
}