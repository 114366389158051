.indexpage {
	.home-banner-under {
		width: 100%;
		padding: 2% 0% 2% 0%;
		background-color: var(--white);
		text-align: center;

		h2 {
			font-size: 26px;
			font-weight: 400;
			text-transform: uppercase;
			color: var(--primarycolor);
			letter-spacing: 2px;
			line-height: 40px;
			margin: 0 0 15px 0;
		}

		h4 {
			font-size: 24px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			color: var(--textcolor);
			margin: 0;
		}
	}

	.sand {
		background: var(--sand);
		padding: 50px 0;

		.box {
			padding: 0 20px;

			.box-inner {
				text-align: center;
				margin: 0 0 20px 0;

				p {
					color: var(--primarycolor);
					font-size: 20px;
					line-height: 30px;
					margin: 0px 0px 15px 0px;
					font-weight: 300;
				}

				.img {
					img {
						border: 7px solid #e0dad4;
						width: 100%;
					}
				}
			}
		}
	}

	.Wir-bieten {
		padding: 30px 0;

		.left {
			h2 {
				font-size: 26px;
				font-weight: 400;
				text-transform: uppercase;
				color: var(--primarycolor);
				letter-spacing: 2px;
				line-height: 40px;
				margin: 0 0 15px 0;
			}

			p {
				color: var(--textcolor);
				font-size: 18px;
				line-height: 30px;
				margin: 0 0 15px 0;
			}
		}

		.right {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.img-box {
				width: 50%;
				position: relative;
				overflow: hidden;
				padding: 0 10px;
				height: 330px;

				img {
					width: auto;
					max-width: unset;
				}

				a {
					background: url(RESOURCE/img/info-bg.png) no-repeat right center;
					text-align: left;
					line-height: 20px;
					padding: 7px 20px 7px 10px;
					margin: 0;
					position: absolute;
					color: var(--primarycolor);
					text-decoration: none;
					text-transform: uppercase;
					bottom: 20px;
					font-size: 18px;
					display: block;

					&:hover {
						color: var(--white);
						opacity: 0.8;
					}
				}
			}

		}
	}


	.Unsere-Objekte {
		background: rgba(56, 81, 108, 0.8);
		text-align: center;
		padding: 30px 0 40px 0;

		h3 {
			font-size: 28px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			margin: 0 0 35px 0;
			color: var(--white);
		}

		.box {
			padding: 0 20px;

			.box-inner {
				text-align: center;
				margin: 0 0 20px 0;

				p {
					color: var(--white);
					font-size: 20px;
					line-height: 30px;
					margin: 0px 0px 15px 0px;
					font-weight: 300;
					letter-spacing: 2px;
				}

				.img {
					img {
						border: 7px solid #e0dad4;
						width: 100%;
					}
				}
			}
		}
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

	@media all and (max-width: 940px) {
		.home-banner-under {
			h2 {
				font-size: 22px;
				line-height: 34px;
			}

			h4 {
				font-size: 20px;
				font-weight: 400;
				letter-spacing: 2px;
				line-height: 32px;
			}
		}

		.Wir-bieten {
			padding: 10px 0;

			.left {
				padding: 0 10px;

				h2 {
					font-size: 22px;
					line-height: 34px;
					margin: 0 0 5px 0;
				}
			}

			.right {
				padding: 0 5px;
			}
		}

		.Unsere-Objekte {
			padding: 10px 0 0 0;

			h3 {
				font-size: 22px;
				line-height: 30px;
				margin-bottom: 5px;
			}

			.box {
				padding: 5px 0;
			}
		}
	}

	@media all and (max-width: 767px) {
		.sand {
			padding: 20px 0;
		}

		.Wir-bieten {
			.right {
				.img-box {
					a {
						font-size: 2.45vw;
						padding-right: 25px;
						line-height: normal;
						padding: 2px 25px 2px 2px;
						bottom: 70px;
					}
				}
			}
		}
	}

	@media all and (min-width: 320px) and (max-width: 481px) {
		.sand {
			.box {
				padding: 0;
			}
		}
	}

	@media all and (min-width: 481px) and (max-width: 767px) {
		.sand {
			.box {
				padding: 0 8px;
				width: 50%;
			}
		}

		.Unsere-Objekte {
			.box {
				padding: 0 8px;
				width: 50%;
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 991px) {
		.sand {
			padding: 30px 0 15px 0;

			.box {
				padding: 0 10px;
			}
		}

		.Unsere-Objekte {
			padding: 10px 0 20px 0;

			.box {
				padding: 10px;
			}
		}
	}

	.home-unit {
		padding: 50px 0;

		h2 {
			font-size: 28px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			margin: 0 0 35px;
			color: var(--primarycolor);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;

			@media (max-width: 940px) {
				font-size: 22px;
				line-height: 34px;
				margin: 0 0 5px;
			}
		}

		.btn-go {
			padding: 7px 10px;
			color: #fff;
			font-size: 22px;
			font-weight: 300;
			background-color: #4c637a;
			max-width: 100%;
			border-radius: 0;
			margin: 0 auto;
			border: 1px solid #4c637a;
			font-weight: 500 !important;
			-webkit-transition: .2s;
			transition: .2s;
			padding: 10px 20px;

			&:hover {
				background-color: #c7c7c6;
				border: 1px solid #c7c7c6;
			}
		}

		.unit-list-section {
			padding: 30px 0 20px;
			margin: 0 0 0px 0;

			.single-unit {
				width: 100%;
				padding: 0;
				text-align: center;
				margin: 0 0 25px 0;

				.stars {

					margin: -10px 0 0 7px;
					font-size: 30px;
					color: #38526C;

					@media (max-width: 588px) {
						margin: 0px 0 0 7px;
					}


				}

				h4 {
					font-size: 20px;
					font-weight: 300;
					line-height: 30px;
					color: var(--primarycolor);
					margin: 0 0 5px;
				}

				.search-result-properties {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 0 10px 0;
				}

				.img-content {
					width: 100%;
					height: auto;
					overflow: hidden;
					border: 7px solid #e0dad4;
					margin: 0 0 10px 0;
					position: relative;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.unit-bottom-add {
					h5 {
						margin: 0;
						font-size: 15px;
						font-weight: 300;
						line-height: 22px;
						color: var(--primarycolor);
					}
				}
			}
		}
	}
}