@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.more-btn-panel {
	display: none;
}

.container-small {
	max-width: 1035px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.map-view {
		.mapnote {
			padding: 15px 10px 20px 10px;

			@media all and (min-width: 768px) and (max-width: 991px) {
				min-height: unset;
				padding: 20px 10px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				padding: 20px 10px;
			}

			@media all and (min-width: 1200px) and (max-width: 1697px) {
				padding: 18px 10px;
			}
		}

		#map-main {
			height: calc(100vh - 177px);
		}
	}

	.mapnote {
		font-size: 18px;
		padding: 15px 0 20px;
		background: #fff;

		a {
			color: var(--primarycolor);

			&:hover {
				color: var(--primarycolor);
			}
		}

		@media (max-width: 767px) {
			padding: 15px 15px;
			font-size: 12px;
			min-height: 85px;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			min-height: 150px;
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			font-size: 14px;
		}
	}

	.mapconsent {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: rgba(26, 121, 187, 0.15);
		flex-direction: column;
		padding: 0 15px;
		font-size: 18px;

		p {
			margin-bottom: 15px;
		}


		a {
			background: var(--primarycolor);
			color: #fff;
			border-radius: 0;
			padding: 0 15px 0 15px;
			height: 36px;
			display: block;
			line-height: 35px;
			text-decoration: none !important;

			&:hover {
				background: var(--primarycolor);
			}
		}
	}

	.inner-banner {
		min-height: 445px;
		background: url(RESOURCE/img/head-index-XL.jpg) no-repeat center;
		background-size: cover;
		background-size: cover;
		position: relative;

		.banner-inner {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.left {
			.left-inner {
				background: url(RESOURCE/img/slogan-bg.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: right center;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				padding: 0 60px;
				align-items: center;
				height: 445px;

				h2 {
					font-size: 70px;
					font-weight: 700;
					line-height: normal;
					color: var(--white);
					margin: 0;

					text-align: left;

					span {
						font-size: 70px;
						font-weight: 300;
					}
				}
			}
		}

		.right {
			.map-btn {
				width: 100%;
				padding: 5px;

				a {
					background: #4c637a;
					color: #fff;
					font-size: 20px;
					padding: 10px 20px;
					text-decoration: unset;
					border: 1px solid #4c637a;

					@media (max-width: 767px) {
						font-size: 16px;
					}

					&:hover {
						background-color: #c7c7c6;
						border: 1px solid #c7c7c6;
					}
				}
			}

			.searchbar {

				margin-top: 0;
				background: transparent;
				padding: 0 0 0 110px;

				.row {
					margin: 0;
					display: flex;
					flex-wrap: wrap;
				}

				.first-input {
					padding: 5px;

					.form-control {
						padding: 10px;
						padding-left: 40px;
						height: 53px;
						font-size: 1.2em;
						position: relative;
						width: 100%;
						border: none;
						background: url(RESOURCE/img/icon-suche-ort.png) no-repeat left center rgba(255, 255, 255, 0.85) !important;
						border-radius: 0;
						text-align: left;
					}
				}

				.search-btn {
					background: rgba(176, 162, 148, 0.85);
					height: 53px;
					border: var(--white);
					line-height: 53px;
					padding: 0 10px;
					box-shadow: none;
					font-size: 22px;
					font-weight: 300;
					transition: 0.3s;

					&:hover {
						background: rgba(56, 81, 108, 0.7);
						color: var(--white);
					}
				}

				.datepicker-trigger {
					.search-popup-trigger {
						background: url(RESOURCE/img/icon-suche-datum.png) no-repeat left center rgba(255, 255, 255, 0.85) !important;
						background-size: 30px 20px !important;
					}
				}

				.guest-trigger {
					.search-popup-trigger {
						background: url(RESOURCE/img/icon-suche-personen.png) no-repeat left center rgba(255, 255, 255, 0.85) !important;
					}
				}

				.searchbox {
					padding: 5px;
					width: 50%;

					#region-trigger,
					.search-popup-trigger {
						padding: 10px;
						padding-left: 40px;
						height: 53px;
						font-size: 18px;
						position: relative;
						width: 100%;
						background: rgba(255, 255, 255, 0.85);
						border: none;
						color: #3E3D40;
						text-align: left;
						border-radius: 0;
					}

					#region-trigger {
						background: rgba(255, 255, 255, 0.85) url(RESOURCE/img/map-pin-icon.png) no-repeat scroll 11px !important;
					}



					input,
					.search-btn,
					select {
						border-radius: 0;
					}

					.search-btn {
						width: 100%;
						font-weight: 400;
						color: var(--white);

					}

					.search-popup-trigger {
						cursor: pointer;
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						padding: 0 40px;

					}

					.form-control:disabled,
					.form-control[readonly] {}
				}

				.last-input {
					padding: 5px;
					margin: 0;

					.zur-erweiterten {
						background: rgba(56, 81, 108, 0.95);
						font-size: 22px;
						font-weight: 300;
						text-align: center;
						color: var(--white);
						border: 1px solid var(--primarycolor);
						text-decoration: none;
						transition: 0.3s;
						width: 100%;
						height: 58px;
						outline: none;

						&:hover {
							background: rgba(56, 81, 108, 0.85);
							color: var(--white);
							border: 1px solid #96a7b9;
							transition: 0.3s;
						}
					}
				}


				@media (min-width:768px) {
					max-width: 650px;
				}

				@media (min-width:993px) {
					max-width: 950px;
				}

			}
		}
	}

	.result-pane {
		width: 50%;
		padding: 0;
		color: #565a5c;


		@media (max-width: 767px) {
			width: 100%;
		}


		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 25px 0 20px 0;
			margin: 0;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}

			h3 {
				margin: 0 0 15px 0;
				color: var(--textcolor);
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			.search-filter {
				margin: 0 0 10px 0;

				a {
					font-size: 18px;
					color: var(--textcolor);
					text-decoration: none;
				}
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		margin: 20px 0 20px 0;

		.result-pane {
			display: none;
		}

		.map-pane {
			height: auto;

			.map-main {
				height: 550px;
			}
		}
	}

	.map-pane {
		.leaflet-popup-content {
			width: 240px !important;
		}

		height: 100vh;
		position: sticky;
		top: 0;

		.map-main {
			height: 100%;

			background-color: #fff;

			.header {
				height: auto;
				padding: 0;
			}

			>div {
				height: 100%;
			}


			.flex {
				height: 100%;

				>div {

					margin-top: 55px;
					height: calc(100% - 55px) !important;
				}
			}


		}

		.search-result-properties {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		h6 {
			margin-top: 10px !important;
		}

		.text-truncate {
			font-family: Source Sans Pro, sans-serif;
			font-size: 16px;
			color: #333;
			margin: 0 0 5px 0;
		}

		.listendata {
			margin: 12px 0 10px 0;
			font-size: 15px;
			font-weight: 300;
			line-height: 22px;
			color: var(--primarycolor);
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
			}
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {
		background-color: var(--white);
	}

	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0 15px;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 100%;
			position: relative;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			margin: 0 0 70px 0;


			.srow-title {
				width: 100%;

				h4 {
					margin: 0 0 5px 0;
					font-size: 40px;
					font-weight: bold;
					color: var(--primarycolor);
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					span {
						font-weight: 300;
						margin: 0 0 0 10px;
					}

					.stars {
						margin: -10px 0 0 7px;

						@media (max-width: 588px) {
							margin: 0px 0 0 7px;
						}


					}
				}
			}

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.srow-blue-info {
				width: 100%;
				background: rgba(56, 81, 108, 0.8);
				padding: 40px 0;

				.container-small {
					display: flex;
					flex-wrap: wrap;
				}

				.image-col {
					width: 34%;
					padding: 0 15px;

					.text-truncate {
						margin: 0px 0px 15px 0px;
						text-align: center;

						a {
							color: var(--white);
							text-decoration: none;
							font-size: 20px;
							font-weight: 300;
							line-height: 30px;
							display: block;

						}
					}

					.img-content {

						.owl-carousel {
							border: 7px solid #e0dad4;
							position: relative;

							.owl-nav {
								.owl-prev {
									position: absolute;
									top: calc(50% - 15px);
									left: 10px;
									color: var(--primarycolor);
									font-size: 35px;
									line-height: 0;
									display: flex;
									align-items: center;
									justify-content: center;
									width: 30px;
									height: 30px;
									background: rgba(256, 256, 256, .7);
									outline: none !important;

									span {
										display: block;
										margin: -5px 0 0 0;
									}
								}

								.owl-next {
									position: absolute;
									top: calc(50% - 15px);
									right: 10px;
									color: var(--primarycolor);
									font-size: 35px;
									line-height: 0;
									display: flex;
									align-items: center;
									justify-content: center;
									width: 30px;
									height: 30px;
									background: rgba(256, 256, 256, .7);
									outline: none;

									span {
										display: block;
										margin: -5px 0 0 0;
									}
								}
							}
						}



						.full-img {
							width: 100%;
							height: 200px;
							object-fit: cover;

						}


						.fav-icon {
							position: absolute;
							cursor: pointer;
							right: 0;
							top: 0;
							color: var(--white);
							margin: 3px;
							font-size: 24px;
							width: 35px;
							text-align: center;
						}


					}

					.search-result-properties {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						margin: 10px 0 0 0;
						padding: 0 5px 0 0;
					}
				}

				.info-col {
					position: relative;
					padding: 0 15px;
					width: 66%;

					.info-col-top {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						width: 100%;
						margin: 0 0 15px 0;

						a.mail {
							width: 40px;
							height: 40px;
							background: url(RESOURCE/img/icon-mail.svg);
							background-position: center;
							background-size: 40px;
							display: flex;
							border: 1px solid var(--white);
							margin: 0 7px 7px;
						}

						img {
							width: 40px;
							border: 1px solid var(--white);
							margin: 0 7px 7px 7px;
						}

						.map-icon-btn {
							background: transparent;
							border: none;
							padding: 0;
						}

						.fav-icon {
							display: flex;

							.fa-heart-o {
								font-size: 0;
								width: 40px;
								height: 40px;
								background: url(RESOURCE/img/icon-favorit.svg);
								background-size: cover;
								border: 1px solid var(--white);
								margin: 0 7px 7px 7px;
								background-position: center;
							}

							.fa-heart {
								font-size: 0;
								width: 40px;
								height: 40px;
								background: url(RESOURCE/img/icon-favorit_no.svg);
								background-size: cover;
								border: 1px solid var(--white);
								margin: 0 7px 7px 7px;
								background-position: center;
							}
						}
					}

					.offer-box {
						background: rgb(56 82 108 / 40%);
						color: rgb(255 255 255 / 70%);
						border: 1px solid rgb(255 255 255 / 20%);
						margin: 10px 0 0 0;

						.offer-text {
							display: flex;
							background: var(--primarycolor);
							padding: 8px 20px;
							font-size: 16px;
							margin: 0 0 10px 0;
						}

						ul {
							padding: 0 0 0 35px;
							margin: 0 0 15px 0;
						}
					}

					.description {
						font-size: 18px;
						line-height: 30px;
						color: var(--white);
						margin: 0 0 10px;
						position: relative;
						overflow: hidden;
						height: 120px;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						-webkit-box-orient: vertical;
					}

					.listendata {
						display: block;
						align-items: center;

						span {
							padding: 0 2% 0 0;
							font-size: 18px;
							line-height: 30px;
							color: var(--white);
						}
					}

					.prices {
						.altheading {
							color: #ffa64d;
							font-size: 16px;
						}

						a {
							font-size: 21px;
							color: var(--white);
							font-weight: bold;
							text-decoration: none;
						}
					}

					.stars {
						position: absolute;
						right: 0;
						bottom: 0;
					}

					.prices {
						.price {
							font-size: 20px;
						}
					}

					.search-result-properties {
						margin-top: 5px;
						margin-bottom: 15px;
					}


				}
			}
		}


	}

	.search-bar {
		padding: 5px 10px;

		.daterange {
			display: inline-block;
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			width: 100%;
			z-index: 9999;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: block !important;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {
			/* width: 100%;
			max-width: 100%; */

			.more-btn-panel {
				display: none;
			}


		}


		.results {
			.srow {
				@mixin flexlayout row;

				.info-col {
					width: 60%;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}
	}


	.search-main {
		min-width: auto;
		min-height: unset;
		flex-direction: unset;
		display: block;

		.layout-row {
			flex-wrap: wrap;
		}
	}

	.pagination-panel-bottom {
		.pagination {
			margin: 0 0 30px 0;
			border-radius: 4px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;

			.active {
				a {
					border: solid 1px var(--primarycolor);
					background-color: var(--primarycolor);
					color: var(--white);
				}
			}

			a {
				border: solid 1px var(--textcolor);
				background-color: #EFEFEF;
				color: var(--textcolor);
				text-decoration: none;
				font-size: 14px;
				font-weight: bold;
				padding: 2px 6px;
				border-radius: 0;
				margin: 0 2px;

				&:hover {
					background: var(--white);
					color: var(--primarycolor) !important;
				}
			}
		}

	}

	.map-view {
		/* .map-pane {
			display: none;
		} */

		.map-pane.full-screen {
			max-width: 100%;
			height: 500px;
			width: 100%;
			display: -webkit-box;
			display: flex;
			margin: 0;
		}

		.search-result-properties {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	#mapModal {
		h4 {
			margin: 0 0 30px 0;
			font-size: 26px;
			font-weight: 400;
			color: var(--primarycolor);
			letter-spacing: 2px;
			line-height: 40px;
			margin: 0 0 15px;
			padding: 0 20px 0 0;

			@media (max-width: 767px) {
				font-size: 20px;
			}
		}

		.map {
			height: 400px;
		}
	}

	@media all and (max-width: 767px) {
		.inner-banner {
			min-height: auto;

			.right {
				.searchbar {
					padding: 0;
				}
			}
		}
	}

	@media all and (max-width: 940px) {

		.results {
			.container-small {
				max-width: 450px;
			}

			.srow {
				margin: 0 0 60px 0;

				.srow-blue-info {
					padding: 15px 0 15px 0;

					.image-col {
						width: 100%;
						padding: 0;

						.img-content {
							.full-img {
								height: auto;
							}
						}
					}

					.info-col {
						width: 100%;
						padding: 15px 0 0 0;

						.info-col-top {

							img,
							a.mail {
								margin: 0 3px 7px;
							}

							.fav-icon {
								.fa-heart-o {
									margin: 0 3px 7px;
								}
							}
						}
					}

				}

				.srow-title {
					h4 {
						font-size: 6vw;
					}
				}

			}
		}
	}

	@media all and (min-width: 941px) and (max-width: 1199px) {
			{

			.results {
				.srow {
					.srow-blue-info {
						.image-col {
							width: 100%;
						}

						.info-col {
							padding-top: 15px;
							width: 100%;
						}
					}
				}
			}
		}
	}

	@media all and (min-width: 1200px) and (max-width: 1350px) {
			{

			.results {
				.srow {
					.srow-blue-info {
						.image-col {
							width: 45%;
						}

						.info-col {
							padding-top: 0;
							width: 55%;
						}
					}
				}
			}
		}
	}


	@media all and (min-width: 768px) and (max-width: 991px) {
		.inner-banner {
			min-height: 530px;

			.left {
				padding: 0;

				.left-inner {
					min-height: 530px;
					padding: 0 0 0 30px;

					h2 {
						font-size: 40px;

						span {
							font-size: 40px;
						}

					}
				}
			}

			.right {
				padding: 0;

				.searchbar {
					padding: 0 0 0 15px;

					.searchbox {
						width: 100%;
					}

					.button-groups {
						.last-input {
							.zur-erweiterten {
								font-size: 16px;
							}
						}

						.searchbox {
							width: 50%;
						}

						.suchen-trigger {
							.search-btn {
								font-size: 16px;
							}
						}
					}

					.advance-filed {
						padding: 10px 0;
						margin: 0 auto;

						.list {
							width: 50%;
							margin: 0;
							padding: 0 15px;

							.checkbox {
								margin: 5px 0;
							}
						}
					}
				}
			}

		}
	}

	@media all and (min-width: 992px) and (max-width: 1199px) {
		.results {
			.srow {
				.srow-title {
					h4 {
						font-size: 5vw;
					}
				}
			}
		}

		.inner-banner {
			min-height: 530px;

			.left {
				.left-inner {
					min-height: 530px;

					h2 {
						font-size: 50px;

						span {
							font-size: 50px;
							font-weight: 300;
						}
					}
				}
			}

			.right {
				.searchbar {
					padding: 0;
					max-width: 100%;

					.searchbox {
						width: 100%;
					}

					.advance-filed {
						.list {
							width: 33.33%;
						}
					}

					.button-groups {
						.last-input {
							.zur-erweiterten {
								font-size: 18px;
							}
						}

						.searchbox {
							width: 50%;
						}

						.suchen-trigger {
							.search-btn {
								font-size: 18px;
							}
						}
					}
				}

			}
		}
	}

	@media all and (min-width: 1200px) and (max-width: 1300px) {
		.inner-banner {
			.right {
				.searchbar {
					padding: 0 0 0 20px;
				}
			}
		}
	}
}