.notfound-main {
	.static-first-section {
		padding: 30px 0 50px 0;

		h2 {
			font-size: 26px;
			font-weight: 400;
			color: var(--primarycolor);
			letter-spacing: 2px;
			line-height: 40px;
			text-align: center;
			margin: 0 0 30px;
		}

		h4 {
			font-size: 24px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			margin: 0 0 15px 0;
			text-align: center;
			color: var(--textcolor);
		}
	}

	@media all and (max-width: 940px) {
		.static-first-section {
			h2 {
				font-size: 22px !important;
				line-height: 34px !important;
				margin-bottom: 5px !important;
			}

			h4 {
				font-size: 20px;
				font-weight: 400;
				letter-spacing: 2px;
				line-height: 32px;
			}
		}
	}
}