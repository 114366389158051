@import "setup.css";

.indexpage {

	.banner-inner {
		display: flex;
		align-items: center;
	}

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.contact-section {
		background-color: var(--white) !important;
	}

	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}
		}
	}



	@media all and (max-width: 767px) {
		.iheader {
			.left {
				display: none;
			}
		}
	}
}