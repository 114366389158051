.booking-view {
	padding: 30px 0;
	overflow: hidden;

	.form-control {
		border-radius: 0 !important;
	}

	.price-table {
		.service-title {
			button {
				display: none;
			}
		}
	}

	.page-header {
		padding: 0;
		margin: 0;
		border: none;

		h1 {
			font-size: 26px;
			font-weight: 400;
			text-transform: uppercase;
			color: var(--primarycolor);
			letter-spacing: 2px;
			line-height: 40px;
			margin: 0 0 15px;
		}
	}

	.cart-details {

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: 400;
			text-align: left !important;
			font-weight: 400;
			font-size: 18px;
			color: var(--primarycolor);
		}

		label.lrequired {}

	}

	.final-col {
		p {
			color: var(--textcolor);
			font-size: 18px;
			line-height: 30px;
			margin: 0 0 0;
		}

		.bottom-buttons-group {
			display: flex;
			align-items: center;
			margin: 30px 0 0 0;
			flex-wrap: wrap;
			justify-content: flex-end;

			.btn-go {
				background: rgba(56, 81, 108, .95);
				font-size: 18px;
				font-weight: 300;
				text-align: center;
				color: var(--white);
				border: 1px solid #96a7b9;
				text-decoration: none;
				-webkit-transition: .3s;
				transition: .3s;
				max-width: 245px;
				width: 100%;
				height: 50px;
				outline: none;
				border-radius: 0;

				&:hover {
					background: rgba(56, 81, 108, .85);
					color: var(--white);
					border: 1px solid #96a7b9;
					-webkit-transition: .3s;
					transition: .3s;
				}
			}

			.btn-default {
				background: rgba(176, 162, 148, .85);
				padding: 0 10px;
				box-shadow: none;
				font-size: 18px;
				font-weight: 300;
				-webkit-transition: .3s;
				transition: .3s;
				height: 50px;
				border: 1px solid var(--white);
				border-radius: 0;
				max-width: 245px;
				width: 100%;
				color: var(--white);

				&:hover {
					background: rgba(56, 81, 108, .7);
					color: var(--white);
				}
			}
		}

		.checks {
			margin-bottom: 30px;

			.checkbox {
				display: flex;
				align-items: center;
				font-size: 18px;
				line-height: 30px;
				flex-wrap: wrap;

				label {
					font-weight: 400;
					font-size: 16px;
					color: var(--textcolor);

					&:before {
						top: 7px;
					}

					&:after {}
				}

				.doc-box {
					a {
						margin: 0 0 0 5px;
						color: var(--primarycolor);
						font-size: 18px;
					}
				}
			}

			.checkbox-success input[type=checkbox]:checked+label:before {
				background-color: var(--sand) !important;
				border-color: var(--sand) !important;
			}



		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}

	.insurancelink {
		a {
			padding: 10px;
			background-color: #b9bec2;
			color: #38516c;
			font-size: 15px;
			line-height: 30px;
			text-decoration: none;
			display: inline-block;
			transition: .2s;
			margin: 20px 0;

			&:hover {
				background-color: #c7c7c6;
			}
		}
	}
}

@media all and (max-width: 940px) {
	.booking-view {
		.final-col {
			.bottom-buttons-group {
				.btn-default {
					margin: 0 auto 15px;
				}

				.btn-go {
					margin: 0 auto;
				}
			}
		}
	}
}