.static-view {
	.button-hell {
		padding: 10px;
		background-color: #b9bec2;
		color: #38516c;
		font-size: 18px;
		line-height: 30px;
		text-decoration: none;
		display: inline-block;
		-webkit-transition: .2s;
		transition: .2s;

		&:hover {
			background-color: #C7C7C6;
		}
	}

	.contact-map {
		height: 400px;
	}

	.section-title {
		font-size: 26px;
		font-weight: 400;
		text-transform: uppercase;
		color: var(--primarycolor);
		letter-spacing: 2px;
		line-height: 40px;
		text-align: center;
		margin: 0 0 30px 0;
	}

	.section-sub-title {
		font-size: 26px;
		font-weight: 400;
		color: var(--primarycolor);
		letter-spacing: 2px;
		line-height: 40px;
		text-align: center;
		margin: 0 0 30px 0;
	}

	.static-first-section {
		padding: 30px 0;

		h4,
		.section-sub-title {
			font-size: 24px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			margin: 0 0 15px 0;
			text-align: center;
			color: var(--textcolor);
		}

		ul {
			li {
				color: var(--textcolor);
				font-size: 18px;
				line-height: 30px;
			}
		}

		.objectlistbutton-section {
			display: flex;
			flex-wrap: wrap;
			margin: 40px 0 40px 0;

			a {
				padding: 10px;
				color: var(--white);
				font-size: 22px;
				font-weight: 300;
				background-color: #4C637A;
				width: 25%;
				border: 4px solid var(--white);
				display: block;
				text-align: center;
				text-decoration: none !important;
				transition: 0.2s;

				&:hover {
					background-color: #C7C7C6;
				}
			}
		}
	}

	.unit-list-section {
		padding: 30px 0;
		background: var(--sand);
		margin: 0 0 50px 0;

		.container {
			display: flex;
			flex-wrap: wrap;

			.single-unit {
				width: 25%;
				padding: 0 20px;
				text-align: center;
				margin: 0 0 25px 0;

				.stars {

					margin: -10px 0 0 7px;
					font-size: 30px;
					color: #38526C;

					@media (max-width: 588px) {
						margin: 0px 0 0 7px;
					}


				}

				h2 {
					font-size: 20px;
					font-weight: 300;
					line-height: 30px;
					color: var(--primarycolor);
					margin: 0 0 5px 0;
				}

				.search-result-properties {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 0 10px 0;
				}

				.img-content {
					width: 100%;
					height: 200px;
					overflow: hidden;
					border: 7px solid #e0dad4;
					margin: 0 0 10px 0;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.unit-bottom-add {
					h5 {
						margin: 0;
						font-size: 15px;
						font-weight: 300;
						line-height: 22px;
						color: var(--primarycolor);
					}
				}
			}
		}
	}

	.links-sec-section {
		padding: 30px 0;
		margin: 0 0 50px 0;


		.full-row {
			margin: 0 0 30px 0;
			padding-bottom: 30px;
			border-bottom: 1px solid var(--sand);
			display: inline-flex;


			.left {
				img {
					width: 100%;
					border: 7px solid #e0dad4;
				}
			}

			.right {
				h4 {
					font-size: 28px;
					font-weight: 400;
					letter-spacing: 2px;
					line-height: 36px;
					margin: 0 0 15px 0;
					color: var(--white);
				}

				p {
					font-size: 18px;
					line-height: 30px;
					color: var(--white);
					margin: 0 0 10px 0;

					a {
						padding: 10px;
						background-color: #b9bec2;
						color: #38516c;
						font-size: 18px;
						line-height: 30px;
						text-decoration: none;
						display: inline-block;
						transition: 0.2s;

						&:hover {
							background-color: #C7C7C6;
						}
					}
				}

			}
		}
	}

	.call-to-action {
		padding: 30px 0;
		margin: 0 0 50px 0;

		h2 {
			font-size: 24px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			color: var(--white);
			margin: 0 0 30px 0;
			text-align: center;
		}

		h6 {
			text-align: center;
			margin: 0;
		}
	}

	.real-estate-offers {
		h5 {
			font-size: 24px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			color: var(--textcolor);
			margin: 0;
		}
	}

	.team-photos {
		margin: 0 0 50px 0;
		display: flex;

		.container {
			display: flex;
			flex-wrap: Wrap;
			justify-content: center;
		}

		.team-box {
			margin: 0 0 30px 0;
			padding: 0 10px;
			width: 20%;

			img {
				width: 100%;
				min-height: 270px;
				object-fit: cover;
				display: block;
				margin: 0 0 10px 0;
			}

			h3 {
				font-size: 28px;
				font-weight: 400;
				letter-spacing: 2px;
				line-height: 36px;
				margin: 0 0 15px 0;
				color: var(--primarycolor);
			}

			p {
				color: var(--textcolor);
				font-size: 18px;
				line-height: 30px;
				margin: 0;
			}
		}
	}

	.wir-bieten {
		padding: 30px 0;
		margin: 0 0 50px 0;

		.left {
			h2 {
				color: var(--white);
				margin: 0 0 15px 0;
			}

			p {
				color: var(--white);
				font-size: 18px;
				line-height: 30px;
				margin: 0 0 10px 0;
			}
		}

		.right {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.img-box {
				width: 50%;
				position: relative;
				overflow: hidden;
				padding: 0 10px;
				height: 330px;

				img {
					width: auto;
					max-width: unset;
				}

				a {
					background: url(RESOURCE/img/info-bg.png) no-repeat right center;
					text-align: left;
					line-height: 20px;
					padding: 7px 20px 7px 10px;
					margin: 0;
					position: absolute;
					color: var(--primarycolor);
					text-decoration: none;
					text-transform: uppercase;
					bottom: 20px;
					font-size: 18px;
					display: block;

					&:hover {
						color: var(--white);
						opacity: 0.8;
					}
				}
			}

		}
	}

	.opening-hrs-section {
		text-align: center;

		p {
			color: var(--textcolor);
			font-size: 18px;
			line-height: 30px;

			span {
				width: 85px;
				margin: 0 8px 0 0;
				display: inline-flex;
				text-align: left;
			}

			a {
				color: var(--textcolor);
				text-decoration: none;

				&:hover {
					text-decoration: underline;

				}

			}
		}
	}

	.contact-form-section {
		h5 {
			text-transform: uppercase;
			font-size: 22px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			margin-bottom: 15px;
			color: var(--textcolor);
			margin: 0 0 10px 0;
			text-align: center;
		}
	}

	.Impressum-main {
		p {
			color: var(--textcolor);
			font-size: 18px;
			line-height: 30px;

			a {
				color: var(--textcolor);
				text-decoration: none;

				&:hover {
					text-decoration: underline;

				}

			}
		}
	}

	.contact-form-section {
		.contact-form {
			.btn-go {
				padding: 7px 10px;
				color: #fff;
				font-size: 22px;
				font-weight: 300;
				background-color: #4C637A;
				max-width: 120px;
				border-radius: 0;
				margin: 0 auto;
				border: 1px solid #4C637A;
				font-weight: 500 !important;
				transition: 0.2s;

				&:hover {
					background-color: #C7C7C6;
					border: 1px solid #C7C7C6;
				}
			}

			.form-group {
				padding: 0;
				margin: 0;

				.col-sm-12 {
					padding: 0;
				}

				textarea {
					height: 254px;
					text-align: left;
				}

				.checkbox {
					color: #666;
					font-size: 18px;
					line-height: 30px;
					text-align: center;
					padding: 20px 0 30px 0;

					label {
						text-align: left;
						padding-left: 25px;

						&:before {
							top: 7px;
							margin-left: 0;
						}

						&:after {
							top: -1px;
							margin-left: 0;
						}
					}

					a {
						color: var(--primarycolor);
						position: relative;
						top: 0;
					}
				}
			}

			.form-control {
				padding-bottom: 11px;
				padding-top: 11px;
				display: inline-block;
				color: #909090;
				font-size: 22px;
				background: rgba(198, 188, 177, 0.4);
				border: 4px solid #fff;
				width: 100%;
				box-shadow: none;
				/*text-align: center;*/
				font-weight: 500;
				box-shadow: none;
				height: 55px;
			}
		}
	}

	@media all and (max-width: 940px) {
		.container-small {
			max-width: 450px;
		}

		.section-title {
			font-size: 22px !important;
			line-height: 34px !important;
			margin-bottom: 5px !important;
		}


		.section-sub-title {
			font-size: 20px !important;
			font-weight: 400 !important;
			letter-spacing: 2px !important;
			line-height: 32px !important;
			margin: 0 !important;
		}

		.static-first-section {
			padding: 10px 0;

			h4 {
				font-size: 20px;
				font-weight: 400;
				letter-spacing: 2px;
				line-height: 32px;
			}

			ul {
				padding: 0 0 0 20px;
			}

			.objectlistbutton-section {
				margin: 40px 0 0 0;

				a {
					width: 100%;
					font-size: 18px;
				}
			}
		}

		.unit-list-section {
			.container {
				.single-unit {
					width: 100%;
					padding: 0;
					text-align: center;
					margin: 0 0 25px;
				}
			}
		}

		.links-sec-section {
			.full-row {
				flex-wrap: wrap;

				.left {
					img {
						margin: 0 0 30px 0;
					}
				}

				.right {
					h4 {
						font-size: 22px;
						line-height: 30px;
						margin-bottom: 5px;
					}
				}
			}
		}

		.call-to-action {
			padding: 10px 0;

			h2 {
				font-size: 20px;
				font-weight: 400;
				letter-spacing: 2px;
				line-height: 32px;
			}
		}

		.team-photos {

			.team-box {
				h3 {
					font-size: 22px;
					line-height: 30px;
					margin-bottom: 5px;
				}
			}
		}

		.wir-bieten {
			.right {
				padding: 0 5px;
			}
		}


		.Impressum-main {
			.left {
				padding: 0;
			}

			.right {

				padding: 0;
			}

		}
	}

	@media all and (min-width: 320px) and (max-width: 480px) {
		.team-photos {

			.team-box {
				width: 50%;

				img {
					height: 160px;
					min-height: auto;
				}
			}
		}

		.wir-bieten {
			padding: 10px 0;

			.left {
				padding: 0;

			}

			.right {
				padding: 0;

				.img-box {
					a {
						font-size: 2.45vw;
						line-height: normal;
						padding: 2px 25px 2px 2px;
						bottom: 70px;
					}
				}
			}
		}
	}

	@media all and (min-width: 481px) and (max-width: 767px) {
		.static-first-section {
			.objectlistbutton-section {
				a {
					width: 50%;
				}
			}
		}

		.unit-list-section {
			.container {
				.single-unit {
					width: 50%;
					padding: 0 7px;
					text-align: center;
					margin: 0 0 25px;

					.img-content {
						height: 150px;
					}
				}
			}
		}

		.team-photos {
			.team-box {
				width: 33.33%;

				img {
					min-height: auto;
					height: 160px;
				}
			}
		}

		.wir-bieten {

			.right {

				.img-box {
					a {
						font-size: 2.45vw;
						line-height: normal;
						bottom: 70px;
						padding: 2px 25px 2px 2px;
					}
				}
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 940px) {
		.static-first-section {
			.objectlistbutton-section {
				a {
					width: 25%;
					font-size: 22px;
					font-weight: 300;
				}
			}
		}

		.unit-list-section {
			.container {
				.single-unit {
					width: 50%;
					padding: 0 10px;
					text-align: center;
					margin: 0 0 25px;

					.img-content {
						height: 230px;
					}
				}
			}
		}

		.team-photos {
			.team-box {
				width: 33.33%;

				h3 {
					font-size: 17px;
					line-height: 1.4em;
					margin-bottom: 5px;
				}

				img {
					min-height: auto;
					height: 230px;
				}
			}
		}

		.wir-bieten {
			.right {
				.img-box {

					a {}
				}
			}
		}
	}

	@media all and (min-width: 941px) and (max-width: 1199px) {
		.static-first-section {
			.objectlistbutton-section {
				a {
					width: 25%;
					font-size: 22px;
					font-weight: 300;
				}
			}
		}

		.team-photos {
			.team-box {
				h3 {
					font-size: 18px;
					line-height: 1.4em;
					margin-bottom: 5px;
				}

				img {
					height: 190px;
					min-height: auto;
				}
			}
		}

		.wir-bieten {
			.right {
				.img-box {
					a {}
				}
			}
		}

		.unit-list-section {
			.container {
				.single-unit {
					width: 25%;
					padding: 0 10px;
					text-align: center;
					margin: 0 0 25px;
				}
			}
		}
	}

	.pagination-panel-bottom {
		.pagination {
			margin: 0 0 30px 0;
			border-radius: 4px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;

			.active {
				a {
					border: solid 1px var(--primarycolor);
					background-color: var(--primarycolor);
					color: var(--white);
				}
			}

			a {
				border: solid 1px var(--textcolor);
				background-color: #EFEFEF;
				color: var(--textcolor);
				text-decoration: none;
				font-size: 14px;
				font-weight: bold;
				padding: 2px 6px;
				border-radius: 0;
				margin: 0 2px;

				&:hover {
					background: var(--white);
					color: var(--primarycolor) !important;
				}
			}
		}

	}
}

.angebote-view {
	.unit-list-section {
		background: transparent;
		margin: 0;
		padding: 50px 0 0 0;


		.single-unit {
			width: 100%;
			padding: 0;
			text-align: center;
			margin: 0 0 25px 0;

			.stars {
				margin: -10px 0 0 7px;
				font-size: 30px;
				color: #38526C;

				@media (max-width: 588px) {
					margin: 0px 0 0 7px;
				}
			}

			h2 {
				font-size: 20px;
				font-weight: 300;
				line-height: 30px;
				color: var(--primarycolor);
				margin: 0 0 5px 0;
			}

			.search-result-properties {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 0 10px 0;
			}

			.img-content {
				width: 100%;
				height: auto;
				overflow: hidden;
				border: 7px solid #e0dad4;
				margin: 0 0 10px 0;
				position: relative;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.unit-bottom-add {
				h5 {
					margin: 0;
					font-size: 15px;
					font-weight: 300;
					line-height: 22px;
					color: var(--primarycolor);
				}
			}
		}
	}

	.angebote-box {
		padding: 30px 0 10px 0;

		h2 {
			font-size: 28px;
			font-weight: 400;
			letter-spacing: 2px;
			line-height: 36px;
			margin: 0 0 0;
			color: #38526c;
			color: var(--primarycolor);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}
	}

	.angebote-blog-boxes {
		padding: 40px 0 10px 0;
		background: rgba(56, 81, 108, .8);
		color: #fff;
		margin-bottom: 50px !important;

		.col-lg-6 {
			margin-bottom: 30px;
		}

		p {
			color: #fff;
			font-size: 18px;
			line-height: 30px;
			margin: 0 0 15px;
		}

		a {
			color: #fff;
		}
	}
}

.maps-section {
	.mapnote {
		margin: 0 0 15px;
		font-size: 18px;
		padding: 0 15px 15px 15px;
		color: var(--textcolor);
		text-align: center;

		a {
			color: var(--primarycolor);

			&:hover {
				color: var(--primarycolor);
			}
		}
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;
		height: 450px;
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;

		.footer-link {
			margin-bottom: 0 !important;
		}

		p {
			text-align: center;
			margin: 0 0 20px 0;
			font-size: 18px;
			color: var(--textcolor);
		}

		>a,
		.footer-link {
			background: var(--primarycolor);
			color: #fff;
			border-radius: 0;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--primarycolor);
			}
		}
	}
}